.service-block {
  width: 100%;
  background: #E8F0FD;
  border: 1px solid #B3C3EE;
  box-shadow: 0px 20px 40px rgba(4, 50, 169, 0.08);
  border-radius: 10px;
  padding: 20px 40px;

  &__setting {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #282F38;

    &:hover{
      text-decoration: underline;
    }

    svg, img {
      margin-right: 8px;
    }
  }

  &__content {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  h4 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;
    line-height: normal;
  }

}