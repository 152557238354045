@import "~assets/scss/variables";


.ant-form-item-has-error {
  input[type="text"],
  input[type="number"] {
    border-color: #C07367;
  }
}

.ant-form-item-explain-error {
  display: flex;
  align-items: center;
  font-size: 17px;
  margin-top: 2px;

  div {
    color: #C07367;
  }

  &:before {
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    background: $error;
    border-radius: 50%;
    margin-right: 8px;
    flex: 0 0 auto;
  }
}

.input {
  position: relative;

  &.is-success {
    input[type="text"],
    input[type="number"] {
      background: #e5f9cf;
      border-color: #b0d884;
    }

    p {
      margin-top: 2px;
      font-size: 14px;
      color: #6B8B48;
      margin-top: 5px;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 13px;
        height: 13px;
        background: #B0D884;
        border-radius: 50%;
        margin-right: 8px;
        flex: 0 0 auto;
      }
    }
  }

  &.is-search {
    input[type="text"],
    input[type="number"] {
      padding-right: 55px;
      border-color: #b6bccd !important;
      background: #fff;
    }
  }

  &.is-footer-search {
    button {
      background: #E6E9ED;

      &:hover,
      &:focus{
        background: #dbe0e7;
      }
    }

    svg {
      fill: $primary;
    }
  }

  small {
    font-size: 16px;
    color: #717C8C;
    margin-top: 2px;
    display: block;
  }

  .input-label {
    position: absolute;
    top: -12px;
    left: 8px;
    z-index: 2;
    padding: 0 5px;
    background: #fff;
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  input[type="text"],
  input[type="number"] {
    border-radius: 8px !important;
    -moz-appearance: textfield;
    z-index: 1;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:active,
    &:focus {
      background: #fff !important;
      border-color: #000;
      box-shadow: none;
    }

    &.ant-input-disabled {
      background-color: #f5f5f5 !important;
      border-color: #b6bccd;
    }
  }

  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-input-group-addon {
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 12px !important;
    left: unset !important;
    margin: auto;
    display: block;
    width: 35px;
    height: 35px;
  }

  button {
    width: 35px !important;
    height: 35px !important;
    border: none;
    background: $primary;
    border-radius: 8px !important;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    .active {
      background: $primary;
    }

    svg {
      fill: white;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: #4772df;
    }
  }
}