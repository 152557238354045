.search {
  position: relative;
  .search-input {
    width: 100%;
  }


  .radio {
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
    :global{

      span{
        font-size: 13px;
      }
    }
  }

  .empty {
    min-height: 60px;
    position: relative;
    z-index: 9999;
  }

  p {
    margin: 0;
  }
  &-empty {
    min-height: 265px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.massage{
  background: #E8F0FD;
  border: 1px solid #B3C3EE;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 25px;
  position: relative;

  .text{
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    svg{
      margin-left: 5px;
      margin-right: 5px;
    }
  }


  .textInfo{
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    flex-wrap: nowrap;
    margin-top: 10px;


    .infoText{
      font-size: 15px;
      line-height: 22px;
      color: #000000;
      display: flex;
      flex-direction: column;
      margin-left: 5px;

      p {
        b{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .icon_wrapper {
      width: 40px;
      height: 40px;
      padding: 10px;
      flex: 0 0 auto;
      background: #4a89dc;
      border-radius: 50%;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 5px;

      svg{
        margin-left: 5px;
        margin-right: 5px;
      }


    }



  }

}
