
.search_card {
  width: 100%;
  background: #E5F9CF;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  &[data-sec='true'] {
    background: #E8F0FD;
    border: 1px solid #B3C3EE;
  }


  .search_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .title_section {
      display: flex;
      overflow-wrap: break-word;
      margin-bottom: 5px;


      span {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        color: #000000;
        text-wrap: revert;


      }

      p {
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        text-transform: uppercase;
        color: #000000;
        text-align:justify;
        word-break: break-word;
        white-space: pre-line;
        overflow-wrap: break-word;
        -ms-word-break: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

      }
    }
  }

  .group-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;


    &[data-size='20'] {
      & {
        margin-left: 20px;
      }
    }


    &[data-size='40'] {
      & {
        margin-left: 40px;
      }
    }

    &[data-size='60'] {
      & {
        margin-left: 60px;
      }
    }

    &[data-size='80'] {
      & {
        margin-left: 80px;
      }
    }

    &[data-size='100'] {
      & {
        margin-left: 100px;
      }
    }


    svg {
      margin-top: -10px;
    }

    span {
      margin-left: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #008810;
      margin-bottom: 0;
    }

  }
}
