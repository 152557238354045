.ant-card .ant-card-bordered .ant-card-hoverable {
  border: none !important;
}
.card {
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #b6bccd !important;
  height: auto;
  border-top: none !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  .cardHeader {
    object-fit: cover;
    width: 100%;
    height: 200px;

    img {
      width: 100%;
      border-radius: 15px 15px 0 0;
      object-fit: cover;
      height: 100%;



    }


    .grey {
      width: 100%;
      height: 6px;
      background: #b3c3ee;
    }
    .red {
      width: 100%;
      height: 6px;
      background: #e9573f;
    }
  }
  .cardBody {
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-decoration-line: underline;
    height: 90px;
    max-height: 90px;
    overflow: hidden;
    margin-bottom: 1em;
    padding: 20px;

    .link {
      color: black;
      &:hover {
        color: #325ecd;
      }
    }
  }
  .badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    background: #e9573f;
    border-radius: 60px;
    font-weight: bold;
    color: white;
  }
  .cardFooter {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-items: end;
    flex: 1 1;
    .date,
    .time {
      margin: 10px 5px;
    }

    .time {
      color: #717c8c;
    }

    .date {
      color: #5b7ed7;
    }
  }
}
