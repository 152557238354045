.ant-switch {
  background-color: #CDD1DD !important;

  &.ant-switch-checked {
    background-color: #325ECD !important;

    &:hover {
      background-color: #2962FF !important;
    }
  }
}

