.tree-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #5C5B7D;
  padding-bottom: 5px;

  .count {
    margin-right: 20px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #54689A;
  }

  .content, .extra {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 15px;
    width: 30px;
    height: 30px;
  }
}

