@import "~assets/scss/variables";

.carousel {
  box-shadow: 0px 20px 20px rgba(135, 138, 146, 0.16);
  border-radius: 8px;
  .slick-prev {
    width: 39px !important;
    height: 72px !important;
    background: rgba(40, 47, 56, .2) !important;
    border-radius: 0 20px 20px 0;
    fill: #fff;
    top: calc(50% - 36px) !important;
    left: 0 !important;
    z-index: 9;
    padding: 0px 14px 0 9px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
        fill: $primary;
    }
  }

  .slick-next {
    width: 39px !important;
    height: 72px !important;
    background: rgba(40, 47, 56, .2) !important;
    border-radius: 20px 0 0 20px;
    fill: #fff;
    top: calc(50% - 36px) !important;
    right: 0 !important;
    z-index: 9;
    padding: 0px 9px 0 14px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      fill: $primary;
    }
  }
}