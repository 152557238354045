
$primary: #325ecd;
$dark: #282f38;
$secondary: #b6bccd;
$error: #E9573F;
$success: #6B8B48;
$barChart: #407fdc;
$theme-font-family:  PT Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
Helvetica Neue, sans-serif;
$theme-font-size: 14px;
//color variables
$EbonyClay: #282f38;
$RollingStone: #777b82;
$EbonyClay: #282f38;
$RollingStone: #777b82;
$Akaroa: #d5b9a7;
$AthensGray: #f6f6f7;
$white: #ffffff;
$CeruleanBlue: #325ecd;
$Raven: #717c8c;
$AthensGray200: #e6e9ed;
$Iron: #d7d8da;
$Heather: #b6bccd;
$Mischka: #cdd1dd;
$HawkesBlue: #e8f0fd;
$Spindle: #b3c3ee;
$Spindle200: #b2c3ee;
$black: #000000;
$Mantis: #8cc152;

