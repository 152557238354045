.service-bar {
  display: flex;
  position: relative;
  .left-content {
    box-shadow: 0px 20px 20px rgba(135, 138, 146, 0.16);

  }

  .right-content {
    position: absolute;
    width: 870px;
    top: 0;
    left: 100%;
    flex: 1;
    z-index: 9999990;
  }
}