.search-response {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  &.no-attribute {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
}