@import 'src/assets/scss/variables';


.empty-card {
  min-height: 130px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #CDD1DD;
  display: flex;
  cursor: pointer;


  &:hover {
    p {
      color: $primary;
    }

    h5 {
      text-decoration: underline;
    }
  }

  &.bg {
    background: #F6F6F7;
    border: none;
  }


  &.horizontal {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    h5 {
      text-align: center;
    }

  }

  &.vertical {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .empty-card__icon {
      width: 50px;
      height: 50px;
      padding: 10px;
      margin: 0;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h5 {
      font-size: 18px;
      line-height: 26px;
    }
  }


  &__icon {
    width: 60px;
    height: 60px;
    padding: 20px;
    flex: 0 0 auto;
    background: #4a89dc;
    border-radius: 50%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

  }


  img {
    width: 100%;
    height: 100%;
  }

  p {
    font-size: 20px;
    text-decoration: underline;
    line-height: 28px;
    color: $dark;
    text-align: center;
    max-width: 200px;
    font-weight: 700;
    transition: all .3s ease;
  }

  h5 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 22px;
    color: $EbonyClay;
  }



  &.empty {
    min-height: 40px;
    background: #fff0f6;
    border-color: #ffadd2;

  .empty-card__icon {
      width: 35px;
      height: 35px;
    }
  }
}
