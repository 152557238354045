@import "~assets/scss/variables";

.service-card {
  width: 100%;


  &__top {
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 30px;
    border-bottom: 1px dashed #B6BCCD;

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 20px 20px rgba(21, 37, 126, 0.22);
      margin-right: 15px;
    }

    h4 {
      margin-bottom: 0;
      font-weight: 600;
      color: $dark;
      font-size: 22px;
    }
  }

  &__bottom {

    ul {
      columns: 2;
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        margin-bottom: 25px;
        display: flex;

        svg {
          margin-top: 5px;
          flex: 0 0 auto;
        }

        a {
          font-size: 17px;
          font-weight: 400;
          line-height: 22px;
          color: $dark;
          border-bottom: 1px solid $dark;
          transition: all .3s ease;
          margin-left: 10px;
          display: block;

          &:hover {
            color: $primary;
            border-color: $primary;
          }
        }
      }
    }

  }
}