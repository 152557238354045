
.body_padding{
   :global{
     .ant-card-body {
       padding: 24px 5px!important;
     }
   }
}

.Card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .img {
    border-bottom: 6px solid #B2C3EE;
  }

  &:hover {
    .img {
      border-bottom: 6px solid #E9573F;
      transition: 0.3s;
    }

    background-color: #E8F0FD;
    transition: box-shadow 0.3s, border-color 0.3s;
    transition-property: box-shadow, border-color;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
  }

  .cardBody {
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-decoration-line: underline;
    padding: 0!important;
    flex: 1;
  }

  .card_content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    flex: 1;
  }
  .cardFooter {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    flex: 1;

    p {
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #282F38;
      text-align: left;
      text-wrap: none!important;
    }

    .badge {
      //width: 74px;
      //height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      background: #E9573F;
      border-radius: 60px;
      font-weight: bold;
      color: white;
    }

    .date, .time {
      margin: 10px 5px;
    }

    .time {
      color: #717c8c;
    }

    .date {
      color: #5B7ED7;
    }
  }
}


