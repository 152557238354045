@import "~assets/scss/variables";

.service-card {
	min-height: 200px;
	padding: 40px 30px;
	background: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-bottom: 1px dashed #b6bccd;
	cursor: pointer;
	transition: all 0.3s ease;

	&:first-child {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	&:last-child {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	&.active {
		border-top-right-radius: 0px;
		background: $primary;
		box-shadow: 0px 20px 20px rgba(21, 37, 126, 0.22);
		position: relative;
		z-index: 1;

		h5 {
			color: #fff;
		}

		.cabinet-btn {
			color: $primary;
			background: #fff;
		}

		.service-btn {
			color: #fff;
			border-bottom: 1px dashed #fff;
		}
	}

	&:last-child {
		border-bottom: 0px;
	}

	h5 {
		text-align: center;
		font-weight: 700;
		font-size: 22px;
		color: $dark;
		line-height: 25px;
		transition: all 0.3s ease;
		margin-bottom: 15px;
	}

	.service-btn {
		color: $dark;
		font-size: 19px;
		border-bottom: 1px solid $dark;
		width: fit-content;
		position: relative;
		display: flex;
		transition: all 0.3s ease;
		align-items: center;
		margin-top: 15px;
		position: relative;

		&:hover {
			svg {
				right: -20px;
			}
		}

		svg {
			position: absolute;
			top: 0;
			bottom: -3px;
			right: -18px;
			margin: auto;
			transition: all 0.2s ease;
		}
	}
}
