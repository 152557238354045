@import 'variables';
@import '~video-react/styles/scss/video-react';

$spaceamounts: (
	-5,
	0,
	3,
	4,
	5,
	6,
	7,
	10,
	14,
	15,
	20,
	25,
	24,
	30,
	35,
	40,
	45,
	50,
	60,
	70,
	80,
	90,
	100,
	-10,
	-15,
	-20,
	-30
); // pixel amounts.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
	@each $side in $sides {
		.m#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}

		.p#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
	}
}

* {
	box-sizing: border-box;

	&:focus {
		outline: none;
	}
}

body {
	font-family: $theme-font-family;
	font-size: $theme-font-size;
	background-color: $AthensGray;
}

a {
	&:hover {
		outline: none;
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	margin-bottom: 0;
}

h1 {
	font-size: 48px;
}

h2 {
	font-size: 44px;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 14px;
}

p {
	font-size: 14px;
}

//-------- Css Helpers -------- //

.h2 {
	font-weight: 700;
	font-size: 40px;
	color: $dark;
	margin: 0;
	line-height: normal;
}

.d-flex {
	display: flex;
	align-items: center;
}

.space-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.white-bg {
	background: #fff;
}

.text-center {
	text-align: center;
}

.fw700 {
	font-weight: 700;
}

.fw400 {
	font-weight: 400;
}

.p-0 {
	padding: 0px !important;
}

#nprogress .bar {
	background: $primary;
	top: 140px;
}

#nprogress .peg {
	box-shadow: 0 0 10px $primary, 0 0 5px $primary;
}

#nprogress .spinner-icon {
	border-top-color: $primary;
	border-left-color: $primary;
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #3d7cdc;
	border: none;
}

.video-react .video-react-big-play-button.big-play-button-hide {
	display: none;
}

.video-react .video-react-big-play-button:before {
	content: '';
	background-image: url('../../assets/svg/play.svg');
	background-repeat: no-repeat;
	background-position: center center;
}

.ant-select-auto-complete .ant-select-clear {
	right: 55px;
	width: 16px;
	height: 16px;

	svg {
		width: 16px;
		height: 16px;
	}
}

.ant-form-item-explain.ant-form-item-explain-error {
	margin-bottom: 15px;
}

.ant-input-number {
	width: 100%;
	background: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}

.ant-col.ant-form-item-label {
}

#jvlabelWrap {
	display: none;
}

.ant-collapse {
	border: none;
	background: #fff;
}

.certain-category-search-dropdown .rc-virtual-list,
.certain-category-search-dropdown .rc-virtual-list-holder {
	max-height: 500px !important;
	height: 500px !important;
}

#ReactSimpleImageViewer {
	background-color: rgba(#ffffffd5, 0.8) !important;
}
