.skeleton {
  width: 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eeeeee;
  background: linear-gradient(to right, #f3f3f3 8%, #eeeef0 18%, #f3f3f3 33%);
  background-size: 800px 104px;
  position: relative;
  content: "";
  border-radius: 4px;

  &.default {
    height: 25px;
  }

  &.large{
    height: 40px;
  }

  &.card {
    min-height: 190px;
  }

  .small {
    height: 18px;
  }

}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
