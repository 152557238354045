.navbar{
  background: #FFFFFF;
  box-shadow: 0px 15px 10px -15px rgba(119, 123, 130, 0.26);
  position: relative;
  .wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .navMenu{
      display: inline-block;
      text-align: start;
      padding: 20px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 28px;
      color: black;
    }
    .active{
      color: #777B82;
    }
  }
}