@import "~assets/scss/variables";


.header {
	padding: 15px 0;
	z-index: 99999;
	align-items: center;
	justify-content: center;

	.logoPart {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.logoTitle {
			font-family: PT Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 17px;
			line-height: 21px;
			max-width: 375px;
			display: flex;
			flex-direction: row;
			@media only screen and (max-width: 376px) {
				font-size: 16px;
				line-height: 16px;
			}

			.logo {
				display: inline-block;
				object-position: center;
				object-fit: contain;
				margin-right: 10px;
				animation-name: rotate;
				animation-duration: 7s;
				animation-iteration-count: infinite;
				animation-timing-function: ease;
				animation-fill-mode: forwards;
			}

			& > a {
				cursor: pointer;
				color: $dark;
			}
		}

		.telegram {
			cursor: pointer;
			display: flex;
			align-items: center;
			.circle {
				width: 15px;
				height: 15px;
				padding: 3px;
				background: #d0b59c;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				svg{
					height: 13px;
				}
			}
		}

		.call {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-content: center;
			padding-right: 20px;

			span {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;

				img {
					margin-right: 7px;
					width: 13px;
					height: 13px;
				}

				p {
					margin: 0;
					font-style: normal;
					font-weight: normal;
					font-size: 20px;
					line-height: 10px;
				}
			}

			p {
				margin: 0;
				text-align: center;
				line-height: 20px;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				color: #777b82;
				align-self: center;
			}
		}

		.elementTop {
			width: 2px;
			height: 25px;
			position: absolute;
			top: -40%;
			right: 5px;
			bottom: 99.14%;
			background: #d5b9a7;
		}
	}

	.avatarPart {
		display: flex;
		position: relative;
		justify-content: flex-start;
		align-items: center;
		padding-left: 10px;

		div {
			display: flex;
			flex-direction: column;
			line-height: 12px;
			justify-content: center;
			align-items: flex-start;

			h6 {
				font-weight: normal;
				padding-left: 10px;
				font-size: 16px;
				text-decoration-line: underline;
				margin-bottom: 10px;
			}

			p {
				padding-left: 10px;
				font-size: 14px;
				color: #777b82;
				margin: 0;
			}
		}
	}

	.langPart {
		display: flex;
		flex-direction: row;
		position: relative;
		justify-content: space-between;
		padding-left: 10px;
		align-items: center;

		.elementTop2 {
			width: 2px;
			height: 25px;
			position: absolute;
			top: -90%;
			left: -10px;
			bottom: 99.14%;
			background: #d5b9a7;
		}
	}
}

.tabletNavbarWrapper {
	background: white;
	padding: 10px 0;

	.tabletNavbarContainer {
		display: flex;
		align-content: center;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.allServices {
			margin: 0;
			font-size: 19px;
			line-height: 26px;
			text-decoration-line: underline;
			color: #282f38;
		}
	}

	.tabletMenu {
		position: absolute;

		.menuContent {
		}
	}
}

.menu-title {
	font-weight: bold;
	font-size: 22px;
	line-height: 28px;
	color: $dark;
	margin-bottom: 22px;
}

.button_log{
	width: 46px;
	height: 46px;
	background: #FFFFFF;
	border-radius: 50%;
	border-color: #fff!important;
}

.menu-item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	img {
		margin-right: 8px;
	}
}

@keyframes rotate {
	0% {
		transform: rotateY(360deg);
	}
	10% {
		transform: rotateY(180deg);
	}
	20% {
		transform: rotateY(360deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}
