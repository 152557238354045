@import "src/assets/scss/variables";

.service-block {
  display: block;
  position: relative;


  &.horizontal {
    .icon {
      margin-bottom: 20px;
    }

    &:hover {
      img{ transform: translateY(-2px);}
    }

    .alert {
      position: absolute;
      top: -5px;
      right: 27px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      background: url("../../../assets/images/icons/alert-icon.svg") no-repeat center center;
    }

    .count {
      position: absolute;
      top: -5px;
      right: 50px;
      width: fit-content;
      display: flex;
      align-items: center;
      background: #325ecd;
      padding: 0 8px;
      line-height: 22px;
      border-radius: 12px;
      font-size: 14px;
      color: white !important;
      text-decoration: none;
      font-weight: 400;
    }

    span {
      color: $dark;
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      display: block;
      text-align: center;
      transition: all .3s ease;
    }
  }

  &.vertical {
    display: flex !important;
    align-items: center;

    &:hover {
      span {
        color: $primary;
      }
    }

    span {
      display: block;
      font-size: 18px;
      color: #282f38;
      margin-left: 20px;
      max-width: 235px;
      line-height: 26px;
      transition: all .3s ease;
    }
  }


  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 100%;
      height: auto;
    }

    img{
      transition: all .3s ease;
    }
  }


}
