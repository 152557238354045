@import "~assets/scss/variables";

.cabinet {
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .header{
    border-bottom: none;
  }

  .button_section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;


    button {
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .switch_section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .track-vertical {
    display: none;
  }

  .codes-list {
    margin: 0 0 5px 0;
    padding: 0;
    list-style: none;
  }

  &-content {
    background: #fff;
    padding: 25px 0 40px 0;
    height: 100%;


    .search-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 40px;
      // margin-top: 38px;


      @media only screen and (max-width: 1030px) {
        flex-direction: column;
        justify-content: flex-start;


        .search_left, .search_right {
          width: 100% !important;
        }

        .search_left {
          margin-bottom: 20px;
        }



      }


      .search_left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;


        .select-section {
          margin-left: 20px;
          margin-right: 10px;
        }

        .search {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .input {
            margin-right: 10px !important;
            background: #FFFFFF;
            flex: 1;

            box-sizing: border-box;
            border-radius: 8px;

            input {
              padding: 8px !important;
            }

            span {

            }
          }


        }
      }

      .search_right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .right_select_section {
          display: flex;
          justify-content: flex-end;
          align-items: center;


          :global {
            .select {
              min-width: 100px !important;
            }
          }


          .text_p {
            font-size: 17px;
            line-height: 22px;
            color: #282F38;
            text-align: center;
            margin-right: 5px;
          }


          .right {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

        }

        .search_right_button {
          margin-right: 5px;
          margin-left: 5px;
          background: #325ECD;
          color: #fff;


        }
      }


    }

  }


  .loading {
    min-height: 200px;
    background: #F6F6F7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .codes-list {
    margin: 0 0 5px 0;
    padding: 0;
    list-style: none;
  }


  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  .codes_li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 10px;
    }
  }
}


.code-generate{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  .inputForm{
    width: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    input{
      width: 580px;
      border: 1px solid #B6BCCD;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 8px 50px 8px 8px!important;
    }
    svg{
      right: 30px;
      position: absolute;
      z-index: 2;
      cursor: pointer;
    }


  }

}


.page-section {
  background: #fff;
  padding: 40px 0 80px 0;
}


.filter {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #CDD1DD;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  padding: 40px 35px;

  @media only screen and (max-width: 1030px) {
    flex-direction: column;
    .middle, .left {
      width: 50%;

    }

    .middle {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }


  .left {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .middle {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;


    button {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 20px;
    }

  }


}

.add-new-product {

  :global{
    .input input[type="text"].ant-input-disabled, .input input[type="number"].ant-input-disabled {
      background: #fff!important;
      color: #000000!important;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @media only screen and (max-width: 480px) {
      justify-content: space-between;
    }
    @media only screen and (max-width: 376px) {
      :global {
        .button.ant-btn-round.ant-btn-lg {
          padding: 6.4px 20px;
        }
      }
    }

    button:first-child {
      margin-right: 10px;
      @media only screen and (max-width: 376px) {
        margin-right: 0;
      }
    }
  }
}

.add-new-product_empty {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.modal_product {

  :global {
    .ant-modal-body {
      .ant-typography {
        text-align: center !important;
      }

    }
  }


}


.table-section {
  position: relative;

}

.table-scroll {
  position: absolute;
  width: 100%;
  top: 40%;
  display: flex;
  justify-content: space-between;

  .left_scroll, .right_scroll {
    cursor: pointer;
    position: absolute;

    svg {
      cursor: pointer;

    }
  }

  .hide{
    display: none;
  }
  .right_scroll {
    position: absolute;
    right: -5px;
  }

  .left_scroll, {
    cursor: pointer;
    position: absolute;
    left: -5px;

    svg {
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
}

.table-horizontal-scroll {


  :global {
    .ant-table-content {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(138, 191, 234, 0.6);
        background-color: #CCCCCC;
      }

      &::-webkit-scrollbar {
        width: 1px;
        height: 10px;
        border-radius: 30px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #5B7ED7;
        width: 10px!important;
        border-radius: 30px;

      }

    }
  }


}

.wrapper_block {
  padding: 5px 3px;
  background: #E5F9CF;
  opacity: 0.8;
  border: 1px solid #6B8B48;
  box-sizing: border-box;
  border-radius: 5px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}


.codes-edit {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #5B7ED7;
    cursor: pointer;

  }

  svg {
    margin-left: 10px;
    cursor: pointer;

  }
}

.auto_complete{

  :global{
    .ant-select-item-option-grouped {
      padding-left: 0px;
    }
  }

  :global{
    .rc-virtual-list-holder {
      padding-left: 0px;

      & >div{
        overflow: auto!important;
      }
    }


  }

  :global{

    .ant-select-item-option-content{
      overflow: auto!important;
    }
  }





}



.radio {
  width: 100%;
  margin: 10px 0 10px 0!important;
  display: flex;

  :global{

    span{
      font-size: 13px;
    }
  }
}

.auto_dropdown{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label_count{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-item_response {
  padding: 2px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;


  h5 {
    margin-bottom: 3px;
    display: block;
  }

  p {
    display: block;
    margin: 0;
    font-size: 18px;
  }
  b{
    font-size: 18px;
  }
  :global {

  }



  &.no-attribute {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
}

.download {
  display: flex;
  align-items: center;
  background: transparent;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media only screen and (max-width: 481px) {
    :global {
      .select {
        width: 100%;
        margin: 10px 0;
      }
      .button {
        width: 100%;
      }
    }
  }

  .label,
  svg {
    margin-right: 10px;
  }
}

.spinner{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.boxRelative{
  position: relative;
}

.CheckIcon{
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 3;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #5B7ED7;
  display: flex;
  justify-content: center;
  align-items: center;


  svg{
    cursor: pointer;


    path{
      fill: #5B7ED7;
    }
  }
}

.text_info{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}