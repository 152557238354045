@import "~assets/scss/variables";

.collapse {
  &:global{
    &.ant-collapse, &.ant-collapse > .ant-collapse-item {
      border: none
    }

    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background: #fff;

      font-weight: bold;
      font-size: 19px;
      line-height: 26px;
      color: $dark;
      border-bottom: 1px dashed #717C8C;
    }
    &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      color: $primary;
    }
  }
   :global {
     .ant-collapse > .ant-collapse-item, .ant-collapse-content {
       border: none
     }
     .ant-collapse-content > .ant-collapse-content-box {
       padding: 0;
     }
   }
}