.info-block {
  min-height: 350px;
  background: #F6F6F7;
  border-radius: 5px;
  padding: 30px 40px;

  h3{
    font-weight: 700;
    margin-bottom: 30px;
  }
}