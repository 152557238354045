*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.\!prose-xl {
  font-size: 1.25rem !important;
  line-height: 1.8 !important;
}

.\!prose-xl :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em !important;
  margin-bottom: 1.2em !important;
}

.\!prose-xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  font-size: 1.2em !important;
  line-height: 1.5 !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.\!prose-xl :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.6em !important;
  margin-bottom: 1.6em !important;
  padding-left: 1.0666667em !important;
}

.\!prose-xl :where(h1):not(:where([class~="not-prose"] *)) {
  font-size: 2.8em !important;
  margin-top: 0 !important;
  margin-bottom: 0.8571429em !important;
  line-height: 1 !important;
}

.\!prose-xl :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.8em !important;
  margin-top: 1.5555556em !important;
  margin-bottom: 0.8888889em !important;
  line-height: 1.1111111 !important;
}

.\!prose-xl :where(h3):not(:where([class~="not-prose"] *)) {
  font-size: 1.5em !important;
  margin-top: 1.6em !important;
  margin-bottom: 0.6666667em !important;
  line-height: 1.3333333 !important;
}

.\!prose-xl :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.8em !important;
  margin-bottom: 0.6em !important;
  line-height: 1.6 !important;
}

.\!prose-xl :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.\!prose-xl :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.\!prose-xl :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.\!prose-xl :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.\!prose-xl :where(figcaption):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em !important;
  line-height: 1.5555556 !important;
  margin-top: 1em !important;
}

.\!prose-xl :where(code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em !important;
}

.\!prose-xl :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.8611111em !important;
}

.\!prose-xl :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em !important;
}

.\!prose-xl :where(pre):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em !important;
  line-height: 1.7777778 !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  border-radius: 0.5rem !important;
  padding-top: 1.1111111em !important;
  padding-right: 1.3333333em !important;
  padding-bottom: 1.1111111em !important;
  padding-left: 1.3333333em !important;
}

.\!prose-xl :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em !important;
  margin-bottom: 1.2em !important;
  padding-left: 1.6em !important;
}

.\!prose-xl :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em !important;
  margin-bottom: 1.2em !important;
  padding-left: 1.6em !important;
}

.\!prose-xl :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.6em !important;
  margin-bottom: 0.6em !important;
}

.\!prose-xl :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4em !important;
}

.\!prose-xl :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.4em !important;
}

.\!prose-xl :where(.\!prose-xl > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.8em !important;
  margin-bottom: 0.8em !important;
}

.\!prose-xl :where(.\!prose-xl > ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em !important;
}

.\!prose-xl :where(.\!prose-xl > ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em !important;
}

.\!prose-xl :where(.\!prose-xl > ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em !important;
}

.\!prose-xl :where(.\!prose-xl > ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em !important;
}

.\!prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.8em !important;
  margin-bottom: 0.8em !important;
}

.\!prose-xl :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 2.8em !important;
  margin-bottom: 2.8em !important;
}

.\!prose-xl :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
}

.\!prose-xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
}

.\!prose-xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
}

.\!prose-xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
}

.\!prose-xl :where(table):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em !important;
  line-height: 1.5555556 !important;
}

.\!prose-xl :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-right: 0.6666667em !important;
  padding-bottom: 0.8888889em !important;
  padding-left: 0.6666667em !important;
}

.\!prose-xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0 !important;
}

.\!prose-xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0 !important;
}

.\!prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding-top: 0.8888889em !important;
  padding-right: 0.6666667em !important;
  padding-bottom: 0.8888889em !important;
  padding-left: 0.6666667em !important;
}

.\!prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0 !important;
}

.\!prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0 !important;
}

.\!prose-xl :where(.\!prose-xl > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0 !important;
}

.\!prose-xl :where(.\!prose-xl > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.\!relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.\!left-10 {
  left: 2.5rem !important;
}

.\!bottom-12 {
  bottom: 3rem !important;
}

.top-0 {
  top: 0px;
}

.right-3 {
  right: 0.75rem;
}

.\!bottom-5 {
  bottom: 1.25rem !important;
}

.\!right-5 {
  right: 1.25rem !important;
}

.top-1\.5 {
  top: 0.375rem;
}

.right-1 {
  right: 0.25rem;
}

.top-1 {
  top: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.-left-\[202\%\] {
  left: -202%;
}

.-top-\[50\%\] {
  top: -50%;
}

.right-\[18px\] {
  right: 18px;
}

.top-\[200px\] {
  top: 200px;
}

.right-0 {
  right: 0px;
}

.\!z-\[9999999\] {
  z-index: 9999999 !important;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[999999\] {
  z-index: 999999;
}

.z-\[1000000\] {
  z-index: 1000000;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.\!m-0 {
  margin: 0px !important;
}

.m-0 {
  margin: 0px;
}

.\!mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.\!my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.\!my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.\!my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.\!my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.\!mt-0 {
  margin-top: 0px !important;
}

.\!mb-0 {
  margin-bottom: 0px !important;
}

.\!mr-2 {
  margin-right: 0.5rem !important;
}

.\!mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.\!mr-3 {
  margin-right: 0.75rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.\!ml-5 {
  margin-left: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-20 {
  margin-top: 5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.\!mb-4 {
  margin-bottom: 1rem !important;
}

.\!ml-2 {
  margin-left: 0.5rem !important;
}

.mb-20 {
  margin-bottom: 5rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.\!ml-0 {
  margin-left: 0px !important;
}

.mr-1 {
  margin-right: 0.25rem;
}

.\!mt-12 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.\!ml-7 {
  margin-left: 1.75rem !important;
}

.ml-10 {
  margin-left: 2.5rem;
}

.\!mb-3 {
  margin-bottom: 0.75rem !important;
}

.mr-60 {
  margin-right: 15rem;
}

.\!ml-3 {
  margin-left: 0.75rem !important;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-40 {
  margin-left: 10rem;
}

.\!ml-1 {
  margin-left: 0.25rem !important;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.\!flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.\!grid {
  display: grid !important;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-\[300px\] {
  height: 300px;
}

.\!h-6 {
  height: 1.5rem !important;
}

.h-6 {
  height: 1.5rem;
}

.\!h-12 {
  height: 3rem !important;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.h-11 {
  height: 2.75rem;
}

.\!h-9 {
  height: 2.25rem !important;
}

.h-\[5px\] {
  height: 5px;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-3 {
  height: 0.75rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[10px\] {
  height: 10px;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-7 {
  height: 1.75rem !important;
}

.\!h-\[20px\] {
  height: 20px !important;
}

.h-8 {
  height: 2rem;
}

.\!h-\[600px\] {
  height: 600px !important;
}

.\!h-\[450px\] {
  height: 450px !important;
}

.h-9 {
  height: 2.25rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.\!h-56 {
  height: 14rem !important;
}

.\!h-24 {
  height: 6rem !important;
}

.h-20 {
  height: 5rem;
}

.\!h-64 {
  height: 16rem !important;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[35px\] {
  height: 35px;
}

.\!h-\[30px\] {
  height: 30px !important;
}

.\!h-full {
  height: 100% !important;
}

.h-\[1px\] {
  height: 1px;
}

.\!h-\[10px\] {
  height: 10px !important;
}

.\!h-\[270px\] {
  height: 270px !important;
}

.h-\[15px\] {
  height: 15px;
}

.\!h-auto {
  height: auto !important;
}

.\!h-8 {
  height: 2rem !important;
}

.\!h-\[50px\] {
  height: 50px !important;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[640px\] {
  height: 640px;
}

.\!h-10 {
  height: 2.5rem !important;
}

.\!h-\[22rem\] {
  height: 22rem !important;
}

.\!h-28 {
  height: 7rem !important;
}

.h-28 {
  height: 7rem;
}

.\!h-4 {
  height: 1rem !important;
}

.h-48 {
  height: 12rem;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.\!w-48 {
  width: 12rem !important;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.\!w-full {
  width: 100% !important;
}

.\!w-9 {
  width: 2.25rem !important;
}

.\!w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem;
}

.w-48 {
  width: 12rem;
}

.w-56 {
  width: 14rem;
}

.\!w-5 {
  width: 1.25rem !important;
}

.w-9 {
  width: 2.25rem;
}

.\!w-3\/5 {
  width: 60% !important;
}

.w-64 {
  width: 16rem;
}

.w-5\/6 {
  width: 83.333333%;
}

.\!w-80 {
  width: 20rem !important;
}

.w-60 {
  width: 15rem;
}

.w-\[27\%\] {
  width: 27%;
}

.w-\[35rem\] {
  width: 35rem;
}

.w-80 {
  width: 20rem;
}

.w-40 {
  width: 10rem;
}

.\!w-2\/3 {
  width: 66.666667% !important;
}

.\!w-\[200px\] {
  width: 200px !important;
}

.\!w-\[300px\] {
  width: 300px !important;
}

.\!w-8 {
  width: 2rem !important;
}

.w-32 {
  width: 8rem;
}

.\!w-40 {
  width: 10rem !important;
}

.\!w-24 {
  width: 6rem !important;
}

.\!w-\[800px\] {
  width: 800px !important;
}

.\!w-6 {
  width: 1.5rem !important;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.\!w-1\/4 {
  width: 25% !important;
}

.\!w-10 {
  width: 2.5rem !important;
}

.w-72 {
  width: 18rem;
}

.\!w-28 {
  width: 7rem !important;
}

.w-3\/4 {
  width: 75%;
}

.\!w-auto {
  width: auto !important;
}

.\!w-14 {
  width: 3.5rem !important;
}

.w-1\/2 {
  width: 50%;
}

.\!max-w-5xl {
  max-width: 64rem !important;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.\!flex-shrink-0 {
  flex-shrink: 0 !important;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.\!cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-default {
  cursor: default;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.\!select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.flex-col {
  flex-direction: column;
}

.\!flex-col {
  flex-direction: column !important;
}

.\!flex-wrap {
  flex-wrap: wrap !important;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.\!items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.\!justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between;
}

.\!justify-between {
  justify-content: space-between !important;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-5 {
  gap: 1.25rem;
}

.\!gap-5 {
  gap: 1.25rem !important;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-8 {
  gap: 2rem;
}

.\!gap-3 {
  gap: 0.75rem !important;
}

.\!gap-8 {
  gap: 2rem !important;
}

.gap-7 {
  gap: 1.75rem;
}

.\!gap-10 {
  gap: 2.5rem !important;
}

.\!gap-2 {
  gap: 0.5rem !important;
}

.gap-10 {
  gap: 2.5rem;
}

.\!gap-1 {
  gap: 0.25rem !important;
}

.\!gap-7 {
  gap: 1.75rem !important;
}

.gap-x-12 {
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-x-5 {
  -webkit-column-gap: 1.25rem;
          column-gap: 1.25rem;
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.\!space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.\!space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.\!space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.\!space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.\!space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.\!overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.\!whitespace-normal {
  white-space: normal !important;
}

.\!whitespace-nowrap {
  white-space: nowrap !important;
}

.\!break-words {
  overflow-wrap: break-word !important;
}

.\!break-all {
  word-break: break-all !important;
}

.\!rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-def {
  border-radius: 3px;
}

.rounded-md {
  border-radius: 0.375rem;
}

.\!rounded-lg {
  border-radius: 0.5rem !important;
}

.\!rounded-full {
  border-radius: 9999px !important;
}

.\!rounded-\[0px\] {
  border-radius: 0px !important;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded {
  border-radius: 0.25rem;
}

.\!rounded-def {
  border-radius: 3px !important;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.border {
  border-width: 1px;
}

.\!border {
  border-width: 1px !important;
}

.\!border-\[1px\] {
  border-width: 1px !important;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.\!border-none {
  border-style: none !important;
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.\!border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-dark\/10 {
  border-color: rgb(0 0 0 / 0.1);
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.\!border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.\!border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.\!border-blue-default {
  --tw-border-opacity: 1 !important;
  border-color: rgb(50 94 205 / var(--tw-border-opacity)) !important;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue-default {
  --tw-border-opacity: 1;
  border-color: rgb(50 94 205 / var(--tw-border-opacity));
}

.\!border-transparent {
  border-color: transparent !important;
}

.\!border-\[\#B6BCCD\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(182 188 205 / var(--tw-border-opacity)) !important;
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.bg-gray-100\/10 {
  background-color: rgb(243 244 246 / 0.1);
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-default {
  --tw-bg-opacity: 1;
  background-color: rgb(50 94 205 / var(--tw-bg-opacity));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-\[\#E5F9CF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 249 207 / var(--tw-bg-opacity));
}

.\!bg-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.\!bg-\[\#E8F0FD\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 240 253 / var(--tw-bg-opacity)) !important;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(50 94 205 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-\[\#FAFBFB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity));
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#FAFBFB\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity)) !important;
}

.\!bg-transparent {
  background-color: transparent !important;
}

.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#E8F0FD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 240 253 / var(--tw-bg-opacity));
}

.bg-primary\/40 {
  background-color: rgb(50 94 205 / 0.4);
}

.\!bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500\/50 {
  background-color: rgb(107 114 128 / 0.5);
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-\[\#F6F6F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 247 / var(--tw-bg-opacity));
}

.bg-\[\#EDEDEF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 239 / var(--tw-bg-opacity));
}

.bg-blue-50\/50 {
  background-color: rgb(239 246 255 / 0.5);
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[\#008810\] {
  fill: #008810;
}

.fill-gray-300 {
  fill: #d1d5db;
}

.fill-transparent {
  fill: transparent;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-yellow-500 {
  stroke: #eab308;
}

.object-contain {
  object-fit: contain;
}

.\!object-contain {
  object-fit: contain !important;
}

.p-2 {
  padding: 0.5rem;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.\!p-7 {
  padding: 1.75rem !important;
}

.\!p-10 {
  padding: 2.5rem !important;
}

.\!p-3 {
  padding: 0.75rem !important;
}

.\!p-2 {
  padding: 0.5rem !important;
}

.p-6 {
  padding: 1.5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.\!px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.\!px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.\!py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.\!py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.\!py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.\!py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.\!px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.\!px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.\!py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.\!py-\[20px\] {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.\!py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.\!px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.\!px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.\!py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.\!px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-8 {
  padding-top: 2rem;
}

.\!pt-2 {
  padding-top: 0.5rem !important;
}

.\!pb-1 {
  padding-bottom: 0.25rem !important;
}

.\!pl-2 {
  padding-left: 0.5rem !important;
}

.\!pr-5 {
  padding-right: 1.25rem !important;
}

.\!pl-5 {
  padding-left: 1.25rem !important;
}

.\!pb-10 {
  padding-bottom: 2.5rem !important;
}

.\!pt-7 {
  padding-top: 1.75rem !important;
}

.\!pl-3 {
  padding-left: 0.75rem !important;
}

.\!pb-5 {
  padding-bottom: 1.25rem !important;
}

.\!pt-5 {
  padding-top: 1.25rem !important;
}

.\!pl-2\.5 {
  padding-left: 0.625rem !important;
}

.\!pt-3 {
  padding-top: 0.75rem !important;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.\!pl-6 {
  padding-left: 1.5rem !important;
}

.\!pr-4 {
  padding-right: 1rem !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.\!text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.\!text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.\!text-\[15px\] {
  font-size: 15px !important;
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.\!text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[5rem\] {
  font-size: 5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.\!font-normal {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.\!font-semibold {
  font-weight: 600 !important;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-blue-default {
  --tw-text-opacity: 1;
  color: rgb(50 94 205 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-\[\#008810\] {
  --tw-text-opacity: 1;
  color: rgb(0 136 16 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.\!text-blue-default {
  --tw-text-opacity: 1 !important;
  color: rgb(50 94 205 / var(--tw-text-opacity)) !important;
}

.\!text-\[\#B7B7B7\] {
  --tw-text-opacity: 1 !important;
  color: rgb(183 183 183 / var(--tw-text-opacity)) !important;
}

.text-dark\/40 {
  color: rgb(0 0 0 / 0.4);
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-\[\#325ECD\] {
  --tw-text-opacity: 1;
  color: rgb(50 94 205 / var(--tw-text-opacity));
}

.text-\[\#777B82\] {
  --tw-text-opacity: 1;
  color: rgb(119 123 130 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.\!text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(50 94 205 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-\[\#5B7ED7\] {
  --tw-text-opacity: 1;
  color: rgb(91 126 215 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-\[\#a54174\] {
  --tw-text-opacity: 1;
  color: rgb(165 65 116 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-60 {
  opacity: 0.6;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.\!ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-\[120ms\] {
  transition-duration: 120ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.\!duration-200 {
  transition-duration: 200ms !important;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-default {
  transition-duration: var(--duration);
}

.duration-300 {
  transition-duration: 300ms;
}

:root {
  --duration: 125ms;
  --color: #325ecd;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
}

::before,
::after {
  --tw-content: '';
}

.ant-select > .ant-select-selector {
  border-radius: 0.5rem !important;
}

#tableID .ant-table-tbody > tr.ant-table-row:hover > td, #tableID .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #5B7ED7 !important;
  color: white !important;
  cursor: pointer
}

#tableID #SVG {
  color: blue !important;
}

#tableID:hover #SVG {
  color: white !important;
}

#tableID .ant-table-tbody .ant-table-row td {
  padding: 7px 16px !important;
}

#tableID .ant-table-thead > tr > th {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
  text-align: center !important;
  font-weight: 700;
}

/*
 *  STYLE 15
 */

.scrollbar
{
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

#style-15::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

#style-15::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

#style-15::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #325ecd;
}

.border-def {
  border: 1px solid
}

.font-def {
  font-family: "PT Sans" !important;
}

.markdown p {
  text-indent: 2.5rem;
}

.markdown * {
  font-family: "PT Sans" !important;
  font-size: 17px !important;
}

.font-def {
  font-family: "PT Sans" !important;
}

.markdown p {
  text-indent: 2.5rem;
}

.markdown ul {
  list-style: disc;
}

.markdown ol {
  list-style: decimal;
  font-size: 16px;
}

.custom img {
  /* height: auto !important; */
  object-fit: contain !important;
}

.custom-pl .ant-select-item-option-grouped {
  padding-left: 0px !important;
}

.spinner_OSmW{
  -webkit-transform-origin:center;
          transform-origin:center;
  -webkit-animation:spinner_T6mA .75s step-end infinite;
          animation:spinner_T6mA .75s step-end infinite
}

@-webkit-keyframes spinner_T6mA{
  8.3%{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
  }

  16.6%{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg)
  }

  25%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg)
  }

  33.3%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg)
  }

  41.6%{
    -webkit-transform:rotate(150deg);
            transform:rotate(150deg)
  }

  50%{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
  }

  58.3%{
    -webkit-transform:rotate(210deg);
            transform:rotate(210deg)
  }

  66.6%{
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg)
  }

  75%{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg)
  }

  83.3%{
    -webkit-transform:rotate(300deg);
            transform:rotate(300deg)
  }

  91.6%{
    -webkit-transform:rotate(330deg);
            transform:rotate(330deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

@keyframes spinner_T6mA{
  8.3%{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
  }

  16.6%{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg)
  }

  25%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg)
  }

  33.3%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg)
  }

  41.6%{
    -webkit-transform:rotate(150deg);
            transform:rotate(150deg)
  }

  50%{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
  }

  58.3%{
    -webkit-transform:rotate(210deg);
            transform:rotate(210deg)
  }

  66.6%{
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg)
  }

  75%{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg)
  }

  83.3%{
    -webkit-transform:rotate(300deg);
            transform:rotate(300deg)
  }

  91.6%{
    -webkit-transform:rotate(330deg);
            transform:rotate(330deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

.nic__spinner {
  -webkit-transform-origin:center;
          transform-origin:center;
  -webkit-animation:spinner_T6mA .75s step-end infinite;
          animation:spinner_T6mA .75s step-end infinite
}

@keyframes spinner_T6mA{
  8.3%{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
  }

  16.6%{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg)
  }

  25%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg)
  }

  33.3%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg)
  }

  41.6%{
    -webkit-transform:rotate(150deg);
            transform:rotate(150deg)
  }

  50%{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
  }

  58.3%{
    -webkit-transform:rotate(210deg);
            transform:rotate(210deg)
  }

  66.6%{
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg)
  }

  75%{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg)
  }

  83.3%{
    -webkit-transform:rotate(300deg);
            transform:rotate(300deg)
  }

  91.6%{
    -webkit-transform:rotate(330deg);
            transform:rotate(330deg)
  }

  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

.placeholder\:text-gray-300::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.placeholder\:text-gray-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(50 94 205 / var(--tw-border-opacity));
}

.hover\:\!border-dark\/10:hover {
  border-color: rgb(0 0 0 / 0.1) !important;
}

.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.hover\:border-blue-default:hover {
  --tw-border-opacity: 1;
  border-color: rgb(50 94 205 / var(--tw-border-opacity));
}

.hover\:\!border-gray-400:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200\/80:hover {
  background-color: rgb(229 231 235 / 0.8);
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.hover\:\!bg-red-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-blue-default:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(50 94 205 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200\/50:hover {
  background-color: rgb(229 231 235 / 0.5);
}

.hover\:\!bg-\[\#E8F0FD\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 240 253 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-blue-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: 0.8;
}

.hover\:fill-gray-400:hover {
  fill: #9ca3af;
}

.hover\:fill-transparent:hover {
  fill: transparent;
}

.hover\:text-blue-default:hover {
  --tw-text-opacity: 1;
  color: rgb(50 94 205 / var(--tw-text-opacity));
}

.hover\:\!text-blue-default:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(50 94 205 / var(--tw-text-opacity)) !important;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(50 94 205 / var(--tw-text-opacity));
}

.hover\:\!text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:\!border-blue-default:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(50 94 205 / var(--tw-border-opacity)) !important;
}

.focus\:\!border-gray-500:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:\!scale-100:active {
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.active\:scale-100:active {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.active\:bg-opacity-100:active {
  --tw-bg-opacity: 1;
}

.active\:bg-opacity-80:active {
  --tw-bg-opacity: 0.8;
}

.peer:focus ~ .peer-focus\:\!text-blue-default {
  --tw-text-opacity: 1 !important;
  color: rgb(50 94 205 / var(--tw-text-opacity)) !important;
}

@media (min-width: 768px) {
  .md\:\!bottom-12 {
    bottom: 3rem !important;
  }

  .md\:\!right-16 {
    right: 4rem !important;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:block {
    display: block;
  }

  .md\:\!h-6 {
    height: 1.5rem !important;
  }

  .md\:h-\[10px\] {
    height: 10px;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:\!w-6 {
    width: 1.5rem !important;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-3 {
    gap: 0.75rem;
  }

  .md\:gap-x-2 {
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
  }

  .md\:gap-x-1 {
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:\!py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:\!pr-5 {
    padding-right: 1.25rem !important;
  }

  .md\:\!pl-5 {
    padding-left: 1.25rem !important;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:\!text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:font-bold {
    font-weight: 700;
  }
}

.\[\&\>\*\:nth-child\(2\)\]\:pl-3>*:nth-child(2) {
  padding-left: 0.75rem;
}

@media (min-width: 768px) {
  .md\:\[\&\>\*\:nth-child\(2\)\]\:pl-5>*:nth-child(2) {
    padding-left: 1.25rem;
  }
}

.\[\&\>\*\:nth-child\(3\)\]\:pl-6>*:nth-child(3) {
  padding-left: 1.5rem;
}

@media (min-width: 768px) {
  .md\:\[\&\>\*\:nth-child\(3\)\]\:pl-10>*:nth-child(3) {
    padding-left: 2.5rem;
  }
}

.\[\&\>\*\:nth-child\(4\)\]\:pl-9>*:nth-child(4) {
  padding-left: 2.25rem;
}

@media (min-width: 768px) {
  .md\:\[\&\>\*\:nth-child\(4\)\]\:pl-16>*:nth-child(4) {
    padding-left: 4rem;
  }
}

@media (min-width: 1024px) {
  .lg\:\!text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}
