.specialist {
  width: 100%;
  min-height: 350px;
  border: 1px solid #CDD1DD;
  border-radius: 5px;
  padding: 30px 40px;
  background: #fff;
  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  .content-left {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .content-right {
    padding-left: 40px;

    h4 {
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }

    p {
      color: #777B82;
      font-size: 17px;
      line-height: 22px;
      margin: 20px 0;
    }

    span {
      font-size: 20px;
      color: #282F38;
      font-weight: 700;
      display: flex;
      align-items: center;
      svg{
        margin-right: 10px;
      }
    }
  }
}