@import "src/assets/scss/variables";

.tax-block {
  width: 100%;
  background: #fff;
  box-shadow: 0px 20px 20px rgba(135, 138, 146, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1px;
  position: relative;
  z-index: 1;

  &.tax-dept{
    margin-bottom: 30px;
    .tax-block__price{
       span,small{
         color: #E9573F;
       }

     }
  }

  &__left {
    flex: 1;
    padding: 25px 40px;
    padding-right: 25px;
    min-height: 140px;
    border-right: 1px dashed #ccc;
  }

  &__right {
    max-width: 245px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
  }

  &__info {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      color: #777B82;
      font-size: 14px;

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:after {
        content: "|";
        display: block;
        margin: 0 5px;
      }
    }
  }

  &__link {
    color: $dark;
    display: block;
    text-decoration: underline;
    padding: 10px 0;
    font-size: 17px;
    line-height: 22px;

    &:hover {
      text-decoration: underline;
    }
  }


  &__title {
    display: flex;
    align-items: flex-end;

    h4 {
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      line-height: normal;
    }

    small {
      margin-left: 10px;
      font-size: 14px;
      display: block;
    }
  }

  &__price {
    margin-bottom: 10px;

    span {
      font-size: 20px;
      font-weight: 600;
      color: $primary;
    }

    small {
      font-size: 18px;
      margin-left: 5px;
      color: $primary;
    }
  }

  &__pay {
    display: flex;
    align-items: center;

    small {
      margin-left: 10px;
      font-size: 14px;
      color: #777B82;
    }
  }

  &__warning{
    display: flex;
    align-items: flex-start;

    svg,img{
      flex: 0 0 auto;
      margin-right: 8px;
    }

     p{
       font-weight: 600;
       font-size: 14px;
       strong{
         color: #E9573F;
         font-weight: 600;
       }

     }
  }
}