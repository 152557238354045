@import '~assets/scss/_variables';

.footerWrapper {
	border-top: 1px solid #d7d8da;
	padding-top: 40px;

	.containerForTablet {
		padding: 0 25px;
		display: grid;
		row-gap: 30px;

		.upAndMenu {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			& > div {
				display: flex;
				flex-direction: row;

				span {
					font-weight: bold;
					font-size: 20px;
					line-height: 28px;
					text-decoration-line: underline;
					color: #282f38;
					padding-left: 10px;
				}
			}
		}
	}

	.iconWrapperForTablet {
		display: grid;
		grid-template-columns: 50% 50%;
		justify-content: flex-end;
		justify-items: center;
		align-items: flex-start;
		align-content: center;
		column-gap: 20px;
		row-gap: 20px;

		.card {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.callCentre {
			justify-self: center;
		}
	}

	.mobileAppsForTablet {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: flex-start;
		margin-bottom: 30px;

		& > div {
			align-self: center;

			& > p {
				margin-bottom: 5px;
			}

			& > div {
				display: grid;
				grid-template-columns: 50% 50%;
				column-gap: 20px;
			}
		}
	}

	.iconWrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: flex-start;
		//margin-bottom: 40px;
		width: 60%;
		margin: 40px auto;
	}

	.card {
		display: flex;
		justify-content: center;
		align-items: center;

		.footerLink {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			align-content: center;
			flex-direction: column;

			a {
				font-size: 19px;
				line-height: 26px;
				color: #282f38;
				display: flex;
				text-decoration-line: underline;
			}

			span {
				font-size: 14px;
				line-height: 20px;
				color: #777b82;
			}
		}

		.icon {
			display: flex;
			width: 59px;
			margin-right: 20px;
		}
	}

	.callCentre {
		display: flex;
		flex-direction: row;
		justify-self: flex-end;
		align-items: center;

		img {
			height: 30px;
			width: 30px;
		}

		div {
			font-weight: bold;
			font-size: 20px;
			line-height: 28px;
			color: $dark;
			padding-top: 9px;
			margin-right: 10px;
		}

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 52px;
			color: $primary;
			margin: 0;
			padding-top: 5px;
			display: flex;
			align-items: center;
			justify-content: center;

			& > div {
				font-weight: bold;
				font-size: 30px;
				line-height: 52px;
				color: $primary;
				margin: 0;
				margin-top: -6px;
				padding-top: 5px;
				user-select: none;
				cursor: pointer;
			}
		}
	}

	.container {
		.contentWrapper {
			margin-top: 60px;
			display: grid;
			grid-template-columns: 36% 60%;
			grid-column-gap: 50px;

			.links {
				display: grid;
				grid-template-columns: 50% 50%;

				div {
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					a {
						font-size: 17px;
						line-height: 20px;
						padding-bottom: 15px;
						text-decoration: underline;
						color: #282f38;
					}
				}
			}

			.searchPart {
				.statisticAndApps {
					margin-top: 20px;
					align-items: center;
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					a {
						font-size: 17px;
						line-height: 22px;
						text-decoration-line: underline;
						color: #282f38;
						max-width: 50%;
					}

					.mobileApps {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						p {
							margin-bottom: 7px;
							color: #282f38;
							font-size: 17px;
							line-height: 22px;
						}

						div {
							display: grid;
							grid-template-columns: 50% 50%;
							grid-column-gap: 10px;
							font-size: 17px;
							line-height: 22px;
							justify-content: space-evenly;
						}
					}
				}
			}
		}
	}

	.outerFooter {
		display: flex;
		flex-direction: column;
		border: 1px solid #d7d8da;

		.containerExtra {
			margin-top: 15px;

			.content {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				flex-wrap: wrap;

				p {
					font-size: 14px;
					line-height: 20px;
					text-align: justify;
					color: #777b82;
					max-width: 33%;
					@media only screen and (max-width: 480px) {
						max-width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 860px) {
	.footerWrapper {
		.containerForTablet {
		}

		.iconWrapperForTablet {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;
		}
	}
}
