.add-new-product {
	.buttons {
		display: flex;
		justify-content: center;
		margin-top: 30px;
		@media only screen and (max-width: 480px) {
			justify-content: space-between;
		}
		@media only screen and (max-width: 376px) {
			:global {
				.button.ant-btn-round.ant-btn-lg {
					padding: 6.4px 20px;
				}
			}
		}

		button:first-child {
			margin-right: 10px;
			@media only screen and (max-width: 376px) {
				margin-right: 0;
			}
		}
	}
}

.modal{
	position: relative;
}

.button_section{
	position: absolute;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	right: 50px;
	top: 50px;

	.button{
		font-weight: 700;
		font-size: 17px;
		line-height: 22px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #325ECD;
		padding: 5px 15px;
		margin-left: 10px;
		background: #FFFFFF;
		border: 1px solid #B6BCCD;
		border-radius: 10px;
		cursor: pointer;


		&.button_active{
			background: #325ECD;
			border: 1px solid #325ECD;
			box-shadow: 0px 20px 20px rgba(24, 74, 209, 0.23);
			border-radius: 10px;
			color: #fff;
		}
	}
}

.add-new-product_empty {
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}



.m-x_input{
	position: relative;

}

.btn-loading {
	position: relative;
	//color: transparent !important
}
.btn-loading:after {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: initial;
	border: 2px solid transparent;
	border-top-color: #115de2;
	-webkit-animation: spin 1s linear infinite;
	animation: spin .8s linear infinite;
	content: "";
	position: absolute;
	//left: 0;
	right: 40px;
	top: 0;
	bottom: 0;
	margin: auto
}
@keyframes spin {
	to {
		-webkit-transform: rotate(1turn)
	}
}
@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(1turn)
	}
}