.category-card {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	transition: all 0.3s;
	cursor: pointer;
	height: 100%;
	z-index: 11;

	&:hover,
	&.active {
		background: #c1d7eb;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);

		svg {
			fill: #fff;

			path {
				fill: #fff;
			}
		}

		.category-card_count {
			color: #fff;
		}
	}

	&_image {
		height: 100%;
		display: flex;
		align-items: flex-end;
		margin: 22px 0;

		svg {
			width: 72px;
			height: 72px;
			fill: #c1d7eb;
		}
	}

	&_title {
		height: 100%;
		font-size: 14px;
		line-height: 18px;
		color: #083592;
		font-weight: bold;
		padding: 0 10px;
		margin-bottom: 18px;
		text-align: center;
		transition: color 0.3s;
		display: flex;
		align-items: flex-end;
	}

	&_count {
		position: absolute;
		right: 10px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #7d99b1;
		transition: color 0.3s;
	}
}
.category-list {
	position: absolute;
	width: 100%;
	margin-top: -105px;
	left: 0;
	right: 0;
	background: #edf6fe;
	z-index: 10;
	padding: 155px 0 30px 0;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;

		li {
			width: 50%;
			display: flex;
			align-items: flex-start;
			margin-bottom: 30px;

			&:hover {
				a {
					color: #083592;
					text-decoration-line: underline;
				}
			}

			a {
				font-size: 14px;
				line-height: 24px;
				color: #000000;
				transition: all 0.3s;
			}

			svg {
				width: 14px;
				height: 14px;
				fill: #000;
				margin: 5px 10px 0;

				path {
					fill: #000 !important;
				}
			}
		}
	}
}
