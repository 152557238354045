.service-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__item{
     width: 190px;
    display: flex;
    justify-content: center;
  }

}
