@import "src/assets/scss/variables";

.search-tree {
  background: $white;
  border: 1px solid $Mischka;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px;


  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }
  .content {
    height: 450px;
    overflow-y: scroll;

    .pagination-row{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      :global{
        ul {

          li{
            margin-right: 5px;
            margin-left: 5px;
            width: 30px;


            a{
              font-size: 10px;
            }


            &.ant-pagination-next{
              width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:after{
                display: none;
              }
            }

            &.ant-pagination-prev{
              width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:before{
                display: none;
              }


            }



          }
        }
      }
    }
  }

  .tree-item {
    :global {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        padding: 0 !important;
      }

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
        margin-left: 15px;
      }
    }
  }
}


.not_fount_tree-item{
  display: flex;
  align-content: center;
  align-items: center;
  color: #d01b1b;
  p{
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
  }
}
