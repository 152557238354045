.page-head {
	background: #f6f6f7;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #e6e9ed;

	&-search {
		width: 100%;
	}

	.bread_crumb_text{
		//text-transform: ;
	}

	&__content {
		text-align: center;
		height: 100%;

		a {
			color: #000 !important;
			text-decoration: underline !important;

			&:hover {
				color: #325ecd !important;
			}
		}

		:global {
			.ant-breadcrumb {
				span {
					&:last-child {
						.ant-breadcrumb-link {
							color: #777b82;
						}
					}
				}
			}
		}

		h1 {
			font-size: 40px;
			font-weight: 700;
			margin-bottom: 0;
			line-height: normal;
			margin-top: 30px;
		}

		.radio {
			width: 100%;
			margin: 20px 0 30px 0;
			display: flex;
		}
	}
}
