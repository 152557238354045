.package {
  border: 1px solid #CDD1DD;
  box-sizing: border-box;
  border-radius: 5px;
  //background: #F6F6F7;
  background: #e8f0fd;
  position: relative;

  .star {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;

    &.active {
      background: #faad14;
      color: #fff;
    }
  }
  &_info {
    padding: 25px 38px;
    &_item {
      display: flex;
    }
  }
  .name {
    background: #fff;
    padding: 30px 40px;
    display: flex;
    font-size: 20px;
    line-height: 28px;
    color: #282F38;
    span {
      color: #777B82;
    }
    h4 {
      color: #325ECD;
      margin-top: -4px;
    }
  }

  .tree {
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0;
     li{
       //height: 25px;
       span {
         background: #fff;
         position: relative;
         z-index: 1;
         display: flex;
         padding: 0 5px;
         margin-left: -3px;
       }
        //&:after {
        //  content: "";
        //  position: absolute;
        //  left: 0;
        //  right: 0;
        //  margin-top: -16px;
        //  border-bottom: 10px dotted #CDD1DD;
        //}
     }
  }

  h5 {
    font-size: 17px;
    line-height: 22px;
    color: #282F38;
    font-weight: bold;
  }
  span {
    font-size: 17px;
    line-height: 22px;
    color: #282F38;
  }
}