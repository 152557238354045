@import "~assets/scss/variables";

.tag {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 25px;

  .circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 6px;
  }

  &.primary {
    color: #5B7ED7;

    .circle {
      background-color: $primary;
    }
  }
  &.success {
    color: $success;

    .circle {
      background-color: $Mantis;
    }
  }

  &.warning {
    color: #E9AF3F;

    .circle {
      background-color: #E9AF3F;
    }
  }

  &.error {
    color: #C07367;

    .circle {
      background-color: $error;
    }
  }
}