@import "src/assets/scss/variables";

.menu {
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:global {
    &.ant-menu {
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }

    &.ant-menu-inline .ant-menu-item {
      line-height: 25px !important;
      height: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      padding: 8px 24px !important;

      & > * {
        flex: unset !important;
        width: 100%;
      }
    }
  }

  :global {
    .ant-menu-submenu-title {
      line-height: 25px !important;
      height: unset !important;
      overflow: unset !important;
      white-space: unset !important;
      padding: 8px 24px !important;

      .ant-badge-count {
        margin-right: 10px;
      }
    }

    //.ant-menu-submenu-selected .ant-menu-submenu-title {
    //  .ant-badge-count {
    //    background: $primary;
    //    color: #fff;
    //    box-shadow: none;
    //  }
    //}

    .ant-badge-count {
      background: #fff;
      color: $primary;
    }

    .ant-menu-submenu-active:not(.ant-menu-submenu-selected) .ant-menu-submenu-title,
    .ant-menu-submenu:not(.ant-menu-submenu-selected):hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: $primary;
    }

    .ant-menu-item.ant-menu-item-selected:hover, .ant-menu-item-active.ant-menu-item-selected {
      color: #fff;
    }

    .ant-menu-item-selected,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: $primary !important;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      color: #fff !important;

      &:after {
        display: none;
      }
    }

    .ant-menu-submenu-selected .ant-menu-submenu-title {
      color: #000;
    }    

    .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-title {
      background: #5B7ED7;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      color: #fff;

      .ant-menu-submenu-arrow {
        color: #fff;
      }
    }
    .ant-menu-submenu-open .ant-menu-submenu-title {
      background: #5B7ED7;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      color: #fff;

      .ant-menu-submenu-arrow {
        color: #fff;
      }
    }

    .ant-menu-item.ant-menu-item-active {
      color: #282F38 !important;
    }
    .ant-menu-item.ant-menu-item-active.ant-menu-item-selected{
      color: #fff !important;
    }

    .ant-menu-item a:hover {
      color: #000;
    }

    .ant-menu-item a:hover {
      color: $primary;
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
      color: #fff;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    margin: 60px 0;
  }
}
