.download {
	display: flex;
	align-items: center;
	background: transparent;
	flex-wrap: wrap;
	margin-bottom: 30px;

	@media only screen and (max-width: 481px) {
		:global {
			.select {
				width: 100%;
				margin: 10px 0;
			}
			.button {
				width: 100%;
			}
		}
	}

	.label,
	svg {
		margin-right: 10px;
	}
}
