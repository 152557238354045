@import "src/assets/scss/variables";

.help {
  background: #E8F0FD;
  border: 1px solid #B3C3EE;
  border-radius: 8px;
  display: flex;
  padding: 20px 60px;
  justify-content: space-between;
  align-items: center;
  p{
    max-width: 290px;
    margin: 0;
    text-align: right;
    margin-left: 5px;
    margin-right: 5px;

    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 15px;
    justify-content: center;
  }

}
