.steps {
	width: 90%;
	height: 39vh;
	font-family: sans-serif;

	.steps__status {
		width: 20%;
		display: flex;
		color: #717c8c;
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 25px;
	}

	.steps__date {
		color: #717c8c;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
	}

	.steps__obligation {
		font-size: 17px;
		font-weight: 700;
		color: #325ecd;
		line-height: 22px;
	}

	.ant-steps-item-title {
		border: 3px;
		border-radius: 20px;
		color: white !important;
		padding: 0 15px !important;
		background-color: #8cc152;
	}

	.square__item {
		height: 10px;
		width: 15px;
		margin: 6px 10px;
	}
}
