.rating-block {
  width: 100%;
  border: 1px solid #CDD1DD;
  border-radius: 10px;
  padding: 5px;
  min-height: 52px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button{
    position: absolute;
    left: 5px;
  }
  &__content {
    display: flex;
    align-items: center;

    @media (max-width: 860px) {}

  }

  &__item {
    display: flex;
    align-items: center;
    padding-right: 40px;

    &:hover {
      p {
        color: #5B7ED7;
      }
    }


    p {
      text-decoration: underline;
      transition: all .3s ease;
      cursor: pointer;
      margin-bottom: 0;
      color: #717C8C;
    }
  }
}

.rating-text{
  margin-right: 10px;
  color: #717C8C;
}
