@import "~assets/scss/variables";

.service-content {
  width: 100%;
  background: #fff;
  border-left: 10px solid $primary;
  box-shadow: 0px 10px 30px rgba(113, 124, 140, 0.3);
  border-radius: 8px;
  border-top-left-radius: 0px;
  padding: 55px;
  position: absolute;
  top: 0;
  left: 0;


  .close {
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
  }
}