.linkStyle{
  color:  #282F38;;
  text-decoration: underline;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  &:hover{
    color:  #282F38;
    text-decoration: underline;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;

  }
}